import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject, Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { catchError, Observable, of } from 'rxjs';

import { AuthService } from '../../auth/auth.service';
import { DialogHelperService } from '../dialog/dialog-helper.service';

import {
  getServerValidationErrors,
  isForbiddenError,
  isUnauthorizedError,
  ServerValidationError,
} from './errors';
import { FormErrorService } from './form-error.service';
import { getErrorMessage } from './get-error-message';

interface BaseCatchActionOptions {
  /**
   * Custom error handler.
   * Return `false` to override the default error handling.
   */
  customHandler?: (error: unknown) => void | boolean;
}

export interface CatchActionErrorOptions extends BaseCatchActionOptions {
  action: string;
}

export interface CatchFormErrorOptions extends BaseCatchActionOptions {
  form: FormGroup;
}

export type CatchOptions = CatchActionErrorOptions | CatchFormErrorOptions;

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private auth = inject(AuthService);
  private dialogHelper = inject(DialogHelperService);
  private errorHandler = inject(ErrorHandler);

  private formErrorService = inject(FormErrorService);

  private showingForbiddenDialog = false;
  private showingUnauthorizedDialog = false;

  /**
   * Use this with the RxJS `pipe()` method to handle errors.
   *
   * See also: {@link handleActionError} and {@link handleFormError}
   *
   * @example
   * apiService.users$
   *   .pipe(errorsService.catch({ form: this.form }))
   *   .subscribe((result) => {...});
   */
  catch(options: CatchOptions) {
    return <T>(observable: Observable<T>): Observable<T | false> =>
      observable.pipe(
        catchError((error) => {
          if (options.customHandler?.(error) === false) {
            return of(false as const);
          }

          if (isCatchActionErrorOptions(options)) {
            void this.handleActionError(options.action, error);
          } else {
            void this.handleFormError(options.form, error);
          }
          return of(false as const);
        }),
      );
  }

  /**
   * Handles server-side action errors.
   *
   * (If the error is related to a form use {@link handleFormError} instead.)
   */
  handleActionError(actionName: string, error: unknown): void {
    if (isUnauthorizedError(error)) {
      return this.handleUnauthorizedError();
    }

    if (isForbiddenError(error)) {
      return this.handleForbiddenError();
    }

    if (error instanceof HttpErrorResponse) {
      const validationErrors = getServerValidationErrors(error);
      if (validationErrors.length > 0) {
        return this.handleActionValidationErrors(validationErrors);
      }
    }

    this.dialogHelper.error({
      message: getErrorMessage(error),
    });

    this.errorHandler.handleError(error);
  }

  /**
   * Handles server-side form errors.
   *
   * Validation errors are assigned to particular controls in the form group if possible,
   * or otherwise assigned to the form group itself.
   *
   * System errors are passed on to the ErrorHandler.
   */
  handleFormError(formGroup: FormGroup, error: unknown): void {
    if (isUnauthorizedError(error)) {
      return this.handleUnauthorizedError();
    }

    if (isForbiddenError(error)) {
      return this.handleForbiddenError();
    }

    if (error instanceof HttpErrorResponse) {
      const validationErrors = getServerValidationErrors(error);

      if (validationErrors.length > 0) {
        let formGroupErrors: ValidationErrors = {};

        validationErrors.forEach(({path, errors}) => {
          const formControl = formGroup.get(path);
          if (formControl) {
            formControl.setErrors(errors);
          } else {
            formGroupErrors = {
              ...formGroupErrors,
              ...errors,
            };
          }
        });

        formGroup.setErrors(formGroupErrors);
        return;
      }
    }

    this.dialogHelper.error({
      message: getErrorMessage(error),
    });

    this.errorHandler.handleError(error);
  }

  handleUnauthorizedError(): void {
    if (!this.showingUnauthorizedDialog) {
      this.showingUnauthorizedDialog = true;
      this.dialogHelper
        .confirm({
          title: 'Session expired',
          message:
            'Your session has expired. Please sign in again to continue.',
          confirmLabel: 'Sign in',
        })
        .subscribe((signIn) => {
          this.showingUnauthorizedDialog = false;
          if (signIn) {
            void this.auth.signOut();
          }
        });
    }
  }

  handleForbiddenError(): void {
    if (!this.showingForbiddenDialog) {
      this.showingForbiddenDialog = true;
      this.dialogHelper
        .error({
          message: "You don't have permission to perform this action.",
        })
        .subscribe(() => {
          this.showingForbiddenDialog = false;
        });
    }
  }

  handleActionValidationErrors(errors: ServerValidationError[]): void {
    const messages = errors.map(
      ({path, errors}) =>
        `${path.join('.')}: ${this.formErrorService.describeValidationErrors(errors)}`,
    );

    this.dialogHelper.error({
      message: `Invalid request: ${messages.join(', ')}.`,
    });
  }
}

function isCatchActionErrorOptions(
  options: CatchOptions,
): options is CatchActionErrorOptions {
  return 'action' in options;
}
