import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { MatListItem } from '@angular/material/list';

@Component({
  selector: 'app-list-placeholder',
  templateUrl: './list-placeholder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatListItem],
})
export class ListPlaceholderComponent {
  lines = input(10);

  lineCollection = computed(() => Array<null>(this.lines()).fill(null));
}
