import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';

export interface ConfirmDialogData {
  title: string;
  message: string;
  cancelLabel?: string;
  confirmLabel: string;
  confirmColor?: ThemePalette;
}

@Component({
  selector: 'app-error-dialog',
  templateUrl: './confirm.dialog.html',
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class ConfirmDialog {
  data = inject<ConfirmDialogData>(MAT_DIALOG_DATA);

  static open(
    dialog: MatDialog,
    data: ConfirmDialogData,
  ): MatDialogRef<ConfirmDialog, boolean> {
    return dialog.open<ConfirmDialog, ConfirmDialogData, boolean>(
      ConfirmDialog,
      {
        disableClose: true,
        width: '360px',
        data,
      },
    );
  }
}
