<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content> {{ data.message }} </mat-dialog-content>

<div mat-dialog-actions align="end">
  <button type="button" mat-button [mat-dialog-close]="false">
    {{ data.cancelLabel ?? 'Cancel' }}
  </button>
  <button
    type="button"
    mat-button
    [color]="data.confirmColor ?? 'primary'"
    [mat-dialog-close]="true"
  >
    {{ data.confirmLabel }}
  </button>
</div>
