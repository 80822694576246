import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import {
  ConfirmDialog,
  ConfirmDialogData,
} from './confirm-dialog/confirm.dialog';
import { ErrorDialog, ErrorDialogData } from './error-dialog/error.dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogHelperService {
  private dialog = inject(MatDialog);

  confirm(options: ConfirmDialogData): Observable<boolean | undefined> {
    return this.openConfirm(options).afterClosed();
  }

  openConfirm(options: ConfirmDialogData) {
    return ConfirmDialog.open(this.dialog, options);
  }

  error(options: ErrorDialogData): Observable<undefined> {
    return ErrorDialog.open(this.dialog, options).afterClosed();
  }
}
