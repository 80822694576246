import { Observable, tap } from 'rxjs';

/**
 * Perform side-effects at the start and first firing of an Observable (even an error/unsubscribe).
 */
export function watch<TResult>(
  start: () => TResult,
  end: (result: TResult) => void,
) {
  return <T>(observable: Observable<T>): Observable<T> => {
    let isComplete = false;

    const result = start();

    const complete = () => {
      if (!isComplete) {
        end(result);
        isComplete = true;
      }
    };

    return observable.pipe(
      tap({
        next: complete,
        error: complete,
        unsubscribe: complete,
      }),
    );
  };
}
