import { Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';

export interface ErrorDialogData {
  message: string;
}

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error.dialog.html',
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
  ],
})
export class ErrorDialog {
  readonly data = inject<ErrorDialogData>(MAT_DIALOG_DATA);

  static open(
    dialog: MatDialog,
    data: ErrorDialogData,
  ): MatDialogRef<ErrorDialog, never> {
    return dialog.open<ErrorDialog, ErrorDialogData, never>(ErrorDialog, {
      width: '360px',
      data,
    });
  }
}
