import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-progress-dialog',
  templateUrl: './progress.dialog.html',
  styleUrls: ['./progress.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [MatProgressSpinner],
})
export class ProgressDialog {
  static open(dialog: MatDialog): MatDialogRef<ProgressDialog, never> {
    return dialog.open(ProgressDialog, {
      disableClose: true,
      backdropClass: 'progress-dialog-backdrop',
      panelClass: 'progress-dialog-panel',
    });
  }
}
