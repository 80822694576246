import { HttpErrorResponse } from '@angular/common/http';
import { ValidationErrors } from '@angular/forms';
import { get, omit } from 'radashi';
import type { ZodIssue } from 'zod';

export interface ServerValidationError {
  path: Array<number | string>;
  errors: ValidationErrors;
}

export function isNotFoundError(error: unknown): boolean {
  return error instanceof HttpErrorResponse && error.status === 404;
}

export function isForbiddenError(error: unknown): boolean {
  return error instanceof HttpErrorResponse && error.status === 403;
}

export function isUnauthorizedError(error: unknown): boolean {
  return error instanceof HttpErrorResponse && error.status === 401;
}

/**
 * If the error indicates a bad request was made,
 * attempts to parse the server errors in a way that is easier to use client-side.
 */
export function getServerValidationErrors(
  error: HttpErrorResponse,
): ServerValidationError[] {
  if (error.status !== 400) {
    return [];
  }

  const issues = get<ZodIssue[] | undefined>(error.error, 'errors');
  if (!issues) {
    return [];
  }

  const errorMap = issues.reduce<Record<string, ServerValidationError>>(
    (errors, issue) => {
      const path = issue.path.join('.');
      return {
        ...errors,
        [path]: {
          path: issue.path,
          errors: {
            ...errors[path]?.errors,
            [issue.code]: omit(issue, ['path']),
          },
        },
      };
    },
    {},
  );

  return Object.values(errorMap);
}
