import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  map,
  MonoTypeOperatorFunction,
} from 'rxjs';

import { watch } from '../custom-operators';

import { ProgressDialog } from './progress.dialog';

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  private dialog = inject(MatDialog);

  private counterSubject = new BehaviorSubject(0);
  private dialogRef?: MatDialogRef<ProgressDialog>;

  constructor() {
    this.counterSubject
      .pipe(
        map((count) => count > 0),
        distinctUntilChanged(),
        // debounce to prevent flickering / unnecessarily showing spinner
        debounceTime(200),
      )
      .subscribe((showProgress) => {
        // https://github.com/angular/material2/issues/5268
        setTimeout(() => {
          if (showProgress) {
            this.dialogRef = this.dialogRef || ProgressDialog.open(this.dialog);
          } else if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = undefined;
          }
        });
      });
  }

  /**
   * Use this with the RxJS `pipe()` method to show a progress indicator while an observable is running.
   *
   * @example
   * apiService.users$
   *   .pipe(progressService.watch())
   *   .subscribe(() => {...});
   */
  watch<T>(): MonoTypeOperatorFunction<T> {
    return watch(
      () => this.incrementCounter(),
      () => this.decrementCounter(),
    );
  }

  wrap<T extends Promise<unknown>>(promise: T): T {
    this.incrementCounter();
    void promise.finally(() => this.decrementCounter());
    return promise;
  }

  private incrementCounter() {
    this.counterSubject.next(this.counterSubject.value + 1);
  }

  private decrementCounter() {
    this.counterSubject.next(this.counterSubject.value - 1);
  }
}
